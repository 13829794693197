import "./sidebar.css";
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import { useContext, Fragment  } from "react";
import { NewMessageData, MessagesData , DatabaseMessages , DatabaseOrders , DatabaseNumber , indexNumber , searchSelected , PrintRoomContext, isPinContext, templateContext , connectionContext, summaryContext , updateblockContext} from "../data/data";
import Selectconversation from "../room/selectconversation";
import { useRef , useState } from "react";
import React from "react";
import {messagescroll} from "../room/Room";
import TextField from "@mui/material/TextField";
import MediaQuery from 'react-responsive'
import Cookies from 'universal-cookie';

export let RemoveItem
export let backButton
export let messagesset
export let sidebarselected
export let Search
export let handleSearch
export var updateDisplay
export var block

export default function Sidebar() {


  const [selectedIndex, setSelectedIndex] = useContext(indexNumber)
  // eslint-disable-next-line no-unused-vars
  const [messages, setMessages] = useContext(MessagesData)
  // eslint-disable-next-line no-unused-vars
  const [Database, setDatabase] = useContext(DatabaseMessages)
  // eslint-disable-next-line no-unused-vars
  const [Orders, setOrders] = useContext(DatabaseOrders)
  // eslint-disable-next-line no-unused-vars
  const [Number, setNumber] = useContext(DatabaseNumber)
  const [PrintSearch, setPrintSearch] = useContext(searchSelected);
  // eslint-disable-next-line no-unused-vars
  const [PrintRoom, setPrintRoom] = useContext(PrintRoomContext)
  // eslint-disable-next-line no-unused-vars
  const [isPin, setisPin] = useContext(isPinContext);
  const sideRef = useRef()
  // eslint-disable-next-line no-unused-vars
  const [NewSearch, setNewSearch] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [template, setTemplate] = useContext(templateContext)
  const [state, setState] = React.useState({});
  const { read,replied,pin } = state;
   // eslint-disable-next-line no-unused-vars
  const [connection, setConnection] = useContext(connectionContext);
     // eslint-disable-next-line no-unused-vars
  const [summary, setSummary] = useContext(summaryContext);
  // eslint-disable-next-line no-unused-vars
  const [updateblock, setupdateblock] = useContext(updateblockContext);
  const [newMessage, setNewMessage] = useContext(NewMessageData);
  const cookies = new Cookies();
  
  /*
  let removeboxes =function () { 
    const boxes = document.querySelectorAll('.makeStyles-guest-10');
    const boxes2 = document.querySelectorAll('.makeStyles-owner-9');
    boxes.forEach(box => {box.remove()});
    boxes2.forEach(box2 => {box2.remove()});
};
*/
  const buttonProps = (value ) => ({
    
    selected: selectedIndex === value,
    onClick: () => {updateDisplay(value);setSelectedIndex(value);RemoveItem(value);setNumber(PrintSearch[value].phone);
      // eslint-disable-next-line
      if(PrintSearch[value].ID.includes("ZZZZ")){
        // eslint-disable-next-line
        setPrintRoom(PrintSearch[value].cname + ' (' + PrintSearch[value].phone + ') \nRepeat: '+ PrintSearch[value].repeat + '\nTimeslot:'+ PrintSearch[value].date +' ' + PrintSearch[value].timeslot + '\nAddress: '+ (PrintSearch[value].address ? PrintSearch[value].address : '') + '\nOrder No: '+ '<a href="https://order.goldenroy.com/wp-admin/post.php?post=' + PrintSearch[value].ID.replace(/Z/g, '')+'&action=edit" target="_blank">'+PrintSearch[value].ID+'</a>');
      }
      else{
              if (PrintSearch[value].ID.includes("DINEIN")) {
              // eslint-disable-next-line
              setPrintRoom(PrintSearch[value].cname + ' (' + PrintSearch[value].phone + ') \nRepeat: '+ PrintSearch[value].repeat + '\nTimeslot:'+ PrintSearch[value].date +' ' + PrintSearch[value].timeslot + '\nAddress: '+ (PrintSearch[value].address ? PrintSearch[value].address : '') + '\nOrder No: '+ '<a href="https://dinein.goldenroy.com/wp-admin/post.php?post=' + PrintSearch[value].ID.replace(/DINEIN/i, '')+'&action=edit" target="_blank">'+PrintSearch[value].ID+'</a>');
            } 
            else{
              // eslint-disable-next-line
            setPrintRoom(PrintSearch[value].cname + ' (' + PrintSearch[value].phone + ') \nRepeat: '+ PrintSearch[value].repeat + '\nTimeslot:'+ PrintSearch[value].date +' ' + PrintSearch[value].timeslot + '\nAddress: '+ (PrintSearch[value].address ? PrintSearch[value].address : '') + '\nOrder No: '+ '<a href="https://goldenroy.com/wp-admin/post.php?post=' + PrintSearch[value].ID+'&action=edit" target="_blank">'+PrintSearch[value].ID+'</a>');
            }
    }

      console.log(PrintSearch[value])
      if(PrintSearch[value].pin===1){
        setisPin(1)
      }
      else{
        setisPin(0)
      }
      var changeread=Orders
      for (var x = 0; x < changeread.length; x++){
          if (changeread[x].phone===  PrintSearch[value].phone){
            changeread[x].read=1
            break
          }
        }
      setOrders(changeread)
      switch(PrintSearch[value].repeat) {
      
      case '1': setTemplate(["Awesome!!! 🥰","Can't thank you enough for all your continuous support again, " +PrintSearch[value].cname+ "! 😊 Really really appreciate it!","We will continue to work hard to keep serving delicious pizzas! 💪"])
      setSummary(["Awesome!!! 🥰","Can't thank you enough for all your continuous support again, " +PrintSearch[value].cname+ "! 😊 Really really appreciate it!","We will continue to work hard to keep serving delicious pizzas! 💪"]);
      break;
      case '2': setTemplate(["Really appreciate all your support, "+PrintSearch[value].cname+"!!","So glad you enjoyed them!!","So glad everyone enjoyed them!!", "Thanks so much again!! 😊"])
      setSummary(["Really appreciate all your support, "+PrintSearch[value].cname+"!!","So glad you enjoyed them!!","So glad everyone enjoyed them!!", "Thanks so much again!! 😊"]);

      break;
      case '3': setTemplate(["Thanks so much for being our regular "+PrintSearch[value].cname+"!!!!","My family and I really really appreciate all your support!!"])
      setSummary(["Thanks so much for being our regular "+PrintSearch[value].cname+"!!!!","My family and I really really appreciate all your support!!"]);

      break;
      case 'New': setTemplate(["Awesome!! 😊 So glad you enjoyed them!","Awesome!! 😊 So glad everyone enjoyed them!","Thanks so much for your support "+PrintSearch[value].cname+"! 😊 Really appreciate it!","Looking forward to sharing our other flavours with you too! 😊","One day come by for our dine-in pizzas! Our dine-in piping hot fresh from oven pizzas are much more delicious than the delivery or takeout ones 😊"])
      setSummary(["Awesome!! 😊 So glad you enjoyed them!","Awesome!! 😊 So glad everyone enjoyed them!","Thanks so much for your support "+PrintSearch[value].cname+"! 😊 Really appreciate it!","Looking forward to sharing our other flavours with you too! 😊","One day come by for our dine-in pizzas! Our dine-in piping hot fresh from oven pizzas are much more delicious than the delivery or takeout ones 😊"]);
      break;

      default: setTemplate(["","","","","",""])
      setSummary(["QUICK REPLY NOT AVAILABLE","FOR CUSTOMER WITH MORE","THAN 4 REPEATS. PLEASE"," TYPE AN APPROPRATE","PROFESSIONAL REPLY","MANUALLY"]);
      
    }
      messagescroll()
    },

  });
  //const customer = [ 'hasdasd', 'egfhfgh', 'ljhkhjkkhj', 'ltrytry', 'ofghfhg','asdadfaf','retdfgdfg','dfgdfherrewt','wesdweds','sdfbrdffdg']
  

    const readstylescount = (read) =>{
    if(read===0){
      return { opacity: '0%'};
    }
    else{
      return { opacity: '100%' };
    }
  }
  const repliedstylescount = (replied) =>{
    if(replied===0){
      return { opacity: '0%'};
    }
    else{
      return { opacity: '100%' };
    }
  }
 
  const pinstylescount = (pin) =>{
    if(pin===1){
      return { opacity: '100%'};
      
    }
    else{
      return { opacity: '0%' };
      
    }
  }

  const connectionstyle= (connection) =>{
    //console.log(connection);
    if(connection===1){

      return {background: 'green'};
    }
    else{

      return {background: 'red'};
    }
  }

  block= (no) =>{
    //console.log(updateblock);
    //console.log(no);
    if(updateblock===no){
      //console.log('Same');

      return {opacity: '75%'};
    
    }
    else{
      //console.log('different');

      return {opacity: '0%'};
    }
  }


  const readstyles = (index) =>{
    if(PrintSearch[index].read===0){
      return { opacity: '100%'};
    }
    else{
      return { opacity: '0%' };
    }
  }
  const repliedstyles = (index) =>{
    if(PrintSearch[index].replied===0){
      return { opacity: '100%'};
    }
    else{
      return { opacity: '0%' };
    }
  }
 
  const pinstyles = (index) =>{
    if(PrintSearch[index].pin===1){

      return { opacity: '100%'};
    }
    else{

      return { opacity: '0%' };
    }
  }

  RemoveItem = function(value) {
    
    Selectconversation(PrintSearch[value].phone)
    setNewMessage('');

    };
  


  updateDisplay = function(value){
    //setNewMessage('');
    const display = []
    var senderId
    var isOwner
    if (value==="refresh"){
      for(var x = 0; x < Database.length; x++) {
        if(Math.abs(Database[x].phone)===Number)
        {
            
            if (Database[x].phone > 0){
              isOwner=true
              senderId=Database[x].phone
            }
            else{
              isOwner=false
              senderId='Agent'
            }

          display.push({'body':Database[x].message,'senderId':senderId,'isOwner':isOwner,'datetime':Database[x].datetime,'pos':Database[x].pos,'neg':Database[x].neg,'mid':Database[x].mid})
        }
      
    };
    
    }
    
      else{
          
          for(var y = 0; y < Database.length; y++) {
            if(Math.abs(Database[y].phone)===PrintSearch[value].phone)
            {
                
                if (Database[y].phone > 0){
                  isOwner=true
                  senderId=Database[y].phone
                }
                else{
                  isOwner=false
                  senderId='Agent'
                }
                
              display.push({'body':Database[y].message,'senderId':senderId,'isOwner':isOwner,'datetime':Database[y].datetime,'pos':Database[y].pos,'neg':Database[y].neg,'mid':Database[y].mid})
              
            }
            
        };
      }
        setMessages(display);
        //await timeout(2000);


  };
 
  backButton = () => {
     sideRef.current.scrollIntoView({behavior: 'instant'}) 
  
     setSelectedIndex()
     setNumber()
  };

  messagesset = (message) => {

    if (Math.abs(message.senderId)===Number){ 
        if(message.senderId<0){
          message.senderId='Agent'
        }
  
        var incomingMessage = {
          ...message,
          isOwner: message.senderId !== 'Agent',
        };
        // send the new message to the others in the same room.
        setMessages((messages) => [...messages, incomingMessage]);

      
      }
  }
  sidebarselected = (message) =>{

    
    for (var x in message){
      
      if (message[x].phone===Number){
        setSelectedIndex(parseInt(x))
      
        break
      }
    }
  }

  handleSearch = () => {
    const countpin = Orders.filter(item => item.pin === 1).length
    const countread = Orders.filter(item => item.read === 0).length
    const countreplied = Orders.filter(item => item.replied === 0).length
    setState({ read:countread,replied:countreplied,pin:countpin})


  

    if (NewSearch !== "") {
      const numberPattern = /^\d+$/; // Matches only numbers (one or more digits)
      const textPattern = /^[A-Za-z]+$/; // Matches only text (one or more alphabetic characters)
      if (numberPattern.test(NewSearch)) {
        // If the variable is a string and contains only numbers
        setPrintSearch(Orders.filter(x => String(x.phone).includes(String(NewSearch))) )
      } else if (textPattern.test(NewSearch)) {
        // If the variable is a string and contains only text
        setPrintSearch(Orders.filter(x => String(x.cname).toLowerCase().includes(String(NewSearch.toLowerCase()))))
      }

      
    }
    else{
      setPrintSearch(Orders)
    }
   
  };

  const handleNewSearch = event => {
    setNewSearch(event.target.value);
    handleSearch()
  };


  return (
   <>
     
     <MediaQuery maxWidth={1200}>
     <div ref={sideRef} />
      <div className="mobilesearch" >
        <TextField
          id="searchbox"
          label={cookies.get('username2')}
          autoComplete="off"
          placeholder="Search"
          variant="outlined"
          value={NewSearch}
          onKeyUp={handleNewSearch}
          onChange={handleNewSearch}
      
          sx={{width: '-webkit-fill-available'}}

        />
      </div>
    </MediaQuery>
    <MediaQuery minWidth={1201}>
    <div ref={sideRef} />
    <div className="search" >
        <TextField
          id="searchbox"
          label={cookies.get('username2')}
          autoComplete="off"
          placeholder="Search"
          variant="outlined"
          value={NewSearch}
          onKeyUp={handleNewSearch}
          onChange={handleNewSearch}
      
          sx={{width: '-webkit-fill-available'}}

        />
      </div>

    </MediaQuery>
    <List sx={{ top:'72px', width: '100%',height:"90vh", overflowY: 'scroll', bgcolor: 'background.paper' }}>
      {Object.keys(PrintSearch).map((currentcustomer,index) => (
      
      <ListItemButton sx={{width:'100%', minWidth:'250px', height:'130px',alignSelf:'start'}} key={index} {...buttonProps(index)}>
        <ListItemAvatar >
          <Avatar alt={`${PrintSearch[index].cname}`} />
        </ListItemAvatar>
        <ListItemText sx={{alignSelf:'start'}}
          primary={`${PrintSearch[index].cname}(${PrintSearch[index].phone}) Repeat:${PrintSearch[index].repeat}`}
           
          secondary={
            <Fragment>
              <Typography
                sx={{ display: 'inline' }}
                component="span"
                variant="body2"
                color="text.primary"
               
              >
                {`${PrintSearch[index].date} ${PrintSearch[index].timeslot}`} 
              </Typography>
              <ListItemText sx={{overflow:'hidden', whiteSpace:'nowrap', textOverflow: 'ellipsis!important'}} secondary={`${PrintSearch[index].message}`}  />

              <span className="read-circle" style={readstyles(index)} />
               <span className="replied-circle" style={repliedstyles(index)} />
               <span className="pin-circle" style={pinstyles(index)} />
            </Fragment>

          }
        />
      </ListItemButton>
                
    ))}


    </List>
    <span className="read-circle-count" style={readstylescount(read)} >{read}</span>
    <span className="replied-circle-count" style={repliedstylescount(replied)} >{replied}</span>
    <span className="pin-circle-count"  style={pinstylescount(pin)}>{pin}</span>
    <span className="connection"  style={connectionstyle(connection)}></span>
    <span className="block"  style={block(Number)}>Another Agent is typing...</span>
    </>
  );
}




