import { useState ,createContext } from "react";
import Home from "../../pages/Home";
import { CookiesProvider } from "react-cookie";

export const NewMessageData=createContext([]);
export const MessagesData=createContext([]);
export const DatabaseMessages=createContext([]);
export const DatabaseOrders=createContext([]);
export const DatabaseNumber=createContext([]);
export const indexNumber=createContext([]);
export const searchSelected=createContext([]);
export const PrintRoomContext=createContext([]);
export const isPinContext=createContext([]);
export const templateContext=createContext([]);
export const connectionContext=createContext([]);
export const copyContext=createContext([]);
export const summaryContext=createContext([]);
export const updateblockContext=createContext([]);
export const lastsentContext=createContext([]);
export const usernameContext=createContext([]);
export const passwordContext=createContext([]);

export default function Data(){
    const [newMessage, setNewMessage] = useState("");
    const [messages, setMessages] = useState([]); 
    // eslint-disable-next-line no-unused-vars
    const [Number,setNumber] = useState(0);
    const [Database, setDatabase] = useState([]); 
    const [Orders, setOrders] = useState([]); 
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [PrintSearch, setPrintSearch] = useState("");
    const [PrintRoom, setPrintRoom] = useState('');
    const [isPin, setisPin] = useState('0');
    const [template, setTemplate] = useState([]);
    const [connection, setConnection] = useState(['0']);
    const [copy, setCopy] = useState([]);
    const [summary, setSummary] = useState([]);
    const [updateblock, setupdateblock] = useState([]);
    const [lastsent, setlastsent] = useState([]);
    const [username, setusername] = useState(['']);
    const [password, setpassword] = useState(['']);

    
return  (<CookiesProvider>
            <MessagesData.Provider value={[messages,setMessages]}>
                      <DatabaseMessages.Provider value={[Database,setDatabase]}>
                        <DatabaseOrders.Provider value={[Orders,setOrders]}>
                            <DatabaseNumber.Provider value={[Number,setNumber]}>
                              <indexNumber.Provider value={[selectedIndex, setSelectedIndex]}>
                                <searchSelected.Provider value={[PrintSearch, setPrintSearch]}>
                                  <PrintRoomContext.Provider value={[PrintRoom, setPrintRoom]}>
                                      <isPinContext.Provider value={[isPin, setisPin]}>
                                        <templateContext.Provider value={[template, setTemplate]}>
                                          <connectionContext.Provider value={[connection, setConnection]}>
                                            <copyContext.Provider value={[copy, setCopy]}>
                                              <summaryContext.Provider value={[summary, setSummary]}>
                                                <updateblockContext.Provider value={[updateblock, setupdateblock]}>
                                                  <lastsentContext.Provider value={[lastsent, setlastsent]}>
                                                    <usernameContext.Provider value={[username, setusername]}>
                                                      <passwordContext.Provider value={[password, setpassword]}>
                                                        <NewMessageData.Provider value={[newMessage,setNewMessage]}>
                                                    <Home />
                                                        </NewMessageData.Provider>
                                                    </passwordContext.Provider>
                                                    </usernameContext.Provider>
                                                  </lastsentContext.Provider>
                                                </updateblockContext.Provider>
                                              </summaryContext.Provider>
                                            </copyContext.Provider>
                                          </connectionContext.Provider>
                                        </templateContext.Provider>
                                      </isPinContext.Provider>
                                    </PrintRoomContext.Provider>
                                  </searchSelected.Provider>
                                </indexNumber.Provider>
                              </DatabaseNumber.Provider>
                          </DatabaseOrders.Provider>
                      </DatabaseMessages.Provider>
                    </MessagesData.Provider>
                  </CookiesProvider>);
}
